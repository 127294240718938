/*
 * For use with _address_autocomplete.html template
 */
import Cookies from 'js-cookie';

window.initMap = function() {};

async function updateAutocompleteDigest(digestField) {
  if (!digestField) {
	return;
  }
  let digest = digestField.value;
  const data = new FormData(digestField.closest("form"));
  if (!data.has("csrftoken")) {
	data.append("csrftoken", Cookies.get("csrftoken"));
  }
  if (!data.has("address1") && data.has("street1")) {
	data.append("address1", data.get("street1"));
  }
  if (!data.has("address2") && data.has("street2")) {
	data.append("address2", data.get("street2"));
  }
  try {
	const response = await fetch("/accounts/_api/account/_address-autocomplete-digest/", {
	  method: "POST",
	  body: data,
	});
	digest = await response.json();
  } catch(error) {
	console.error("Failed to obtain autocomplete digest:", error);
	return;
  }
  digestField.value = digest;
}

export function autocompleteRegion(components, regionSelect, digestField) {
  const regionName = components.administrative_area_level_1.long_name;
  const regionCode = components.administrative_area_level_1.short_name.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
  const simplifiedRegionName = components.administrative_area_level_1.long_name.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
  const regionCodeMatch = regionSelect.querySelector(`option[value="${regionCode}"]`) || regionSelect.querySelector(`option[value="${regionName}"]`) || regionSelect.querySelector(`option[value="${simplifiedRegionName}"]`);
  if (regionCodeMatch !== null) {
	regionCodeMatch.selected = true;
  } else {
	regionSelect.add(new Option(regionName, simplifiedRegionName, true));
  }
  updateAutocompleteDigest(digestField);
}
window.autocompleteRegion = autocompleteRegion;

export function changeInputValue(input, value) {
  input.value = value;
  input.dispatchEvent(new Event("change"));
}
window.changeInputValue = changeInputValue;

